import React, { useEffect, useState } from 'react'
import './InputRange.css'

function InputRange({ min, max, onMinChange = () => {}, onMaxChange = () => {}}) {
  const [valueA, setValueA] = useState(min);
  const [valueB, setValueB] = useState(max);

  const handleChangeA = (event) => {
    let temp = parseInt(event.target.value, 10);
    if (temp < valueB) {
      setValueA(temp);
      onMinChange(Math.min(valueA, temp))
    }
  };
  const handleChangeB = (event) => {
    let temp = parseInt(event.target.value, 10);
    if (temp > valueA) {
      setValueB(temp);
      onMaxChange(Math.max(valueA, temp))      
    }
  };

  const start = Math.round((Math.min(valueA, valueB) / max) * 100);
  const diff = Math.round((Math.abs(valueA - valueB) / max ) * 100);

  return (
    <div className='inputRange'>
      <div style={{ position: 'relative', width: '100%'}}>
        <div
          style={{
            position: 'absolute',
            borderRadius: '9999px',
            backgroundColor: '#526035',
            left: `${start}%`,
            width: `${diff}%`,
            height: '12px',
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            display: 'grid',
            placeItems: 'center',
            width: '12px',
            height: '12px',
            left: `${start}%`,
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '9999px',
              display: 'grid',
              placeItems: 'center',
              width: '10px',
              height: '10px',
            }}
          >
            <div
              style={{
                borderRadius: '9999px',
                width: '8px',
                height: '8px',
                backgroundColor: '#526035',
              }}
            ></div>
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            display: 'grid',
            placeItems: 'center',
            top: '0',
            width: '12px',
            height: '12px',
            left: `calc(${(valueB / max) * 100}% - 12px)`,
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '9999px',
              display: 'grid',
              placeItems: 'center',
              width: '10px',
              height: '10px',
            }}
          >
            <div
              style={{
                borderRadius: '9999px',
                width: '8px',
                height: '8px',
                backgroundColor: '#526035',
              }}
            ></div>
          </div>
        </div>
        <input
          type="range"
          id="demoBInputA"
          name="demoBInputA"
          min={min}
          max={max}
          step={1}
          value={valueA}
          onChange={handleChangeA}
          style={{ position: 'absolute', width: '100%', top: '0', left: '0', height: '24px', opacity: '0', cursor: 'pointer' }}
        />
        <input
          type="range"
          id="demoBInputB"
          name="demoBInputB"
          min={min}
          max={max}
          step="1"
          value={valueB}
          onChange={handleChangeB}
          style={{ position: 'absolute', width: '100%', top: '0', left: '0', height: '24px', opacity: '0', cursor: 'pointer' }}
        />
      </div>
    </div>
  );
}

export default InputRange