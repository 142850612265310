import { React, useState } from "react";
import "./Calendar.css";

export default function Calendar({
  dueDate = new Date(),
  expDate = new Date(),
  currentDate,
  setCurrentDate,
  amount,
  isEditable = true
}) { 
  const [showDate, setDate] = useState(new Date());
  const findMonthDays = (y, m) => {
    return new Date(y, m + 1, 0).getDate();
  };

  const findFirstDay = (y, m) => {
    return new Date(y, m, 1).getDay();
  };

  const changeToPrevMonth = () => {
    if(isEditable){
      setDate((pDate) => {
        const pMonth = pDate.getMonth() - 1;
        const pYear = pDate.getFullYear();
        return new Date(pYear, pMonth);
      });
    }
  };

  const changeToNextMonth = () => {
    if(isEditable){
      setDate((pDate) => {
        const nMonth = pDate.getMonth() + 1;
        const nYear = pDate.getFullYear();
        return new Date(nYear, nMonth);
      });
    }
  };

  const handleDateClick = (date) => {
    if(new Date() - date <= 86000000){
      setDate(date);
      setCurrentDate(date)
    }
  };

  const showCalendar = () => {
    //const currDate = new Date();
    let y = currentDate.getFullYear();
    let m = currentDate.getMonth();
    let mDays = findMonthDays(y, m);
    let fDay = findFirstDay(y, m);
    if(showDate?.toISOString().split("T")[0] !== currentDate?.toISOString().split("T")[1]){
      y = showDate?.getFullYear();
      m = showDate?.getMonth()
      mDays = findMonthDays(y, m);
      fDay = findFirstDay(y, m);
    }
    const allDays = [];

    // For empty cells
    for (let p = 0; p < fDay; p++) {
      allDays.push(<div key={`em-${p}`} className="box empty"></div>);
    }

    // Show actual days
    for (let d = 1; d <= mDays; d++) {
      var className = "";
      const date = new Date(y, m, d);
      const isSelected =
        currentDate && date.toISOString()?.split("T")[0] === currentDate.toISOString()?.split("T")[0];
      const isDueDate =
        dueDate && date.toDateString() === dueDate.toDateString();
      const isExpDate =
        expDate && date.toDateString() === expDate.toDateString();
        
      if (isSelected) {
        className = "selected";
      }
      if (isDueDate) {
        className = "due";
      }
      if (isExpDate) {
        className = "lateFee";
      }

      allDays.push(
        <div
          key={`d-${d}`}
          className={`box ${className}`}
          onClick={() => handleDateClick(date)}
        >
          {d}
        </div>
      );
    }

    return allDays;
  };

  return (
    <div>
      <div className="popup-container">
        <div className="Calendar-container">
          <div className="CalHeader">
            <button type='button' onClick={changeToPrevMonth}> &lt; </button>
            <span>
              {showDate.toLocaleString("default", {
                month: "long",
                year: "numeric",
              })}
            </span>
            <button type='button' onClick={changeToNextMonth}> &gt; </button>
          </div>
          <div className="weekdays">
            <span className="daySpecifier">Sun</span>
            <span className="daySpecifier">Mon</span>
            <span className="daySpecifier">Tue</span>
            <span className="daySpecifier">Wed</span>
            <span className="daySpecifier">Thu</span>
            <span className="daySpecifier">Fri</span>
            <span className="daySpecifier">Sat</span>
          </div>
          <div className="CalBody">{showCalendar()}</div>
        </div>
        <div className="ImpDatesDescription">
          <div className="impDates">
            <div className="impDatesTitle">Important Dates</div>
            <div className="greenIndicates">
              <div className="greenBox"></div>
              <div>
                Your payment is due{" "}
                {dueDate.toLocaleString("default", { month: "long" })}{" "}
                {dueDate.getDate()}
              </div>
            </div>
            <div className="redIndicates">
              <div className="redBox"></div>
                <div>
                Your payment will be late and you will owe a ${amount} late fee if you pay on or after {expDate.toLocaleString("default", { month: "long" })} {expDate.getDate()}
                </div>
              
            </div>
            <div className="notePayment">
            
              <b>Note: </b>Payments must be submitted before 11:59 p.m. ET in
              order to be credited the same day.{" "}
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
